<template>
  <div>
    <div class="flex items-center gap-1.5">
      <div
        class="grid h-12 w-12 place-items-center whitespace-nowrap rounded-full border bg-white text-lg text-primary dark:border-neutral-800 dark:bg-neutral-900"
      >
        {{ bonuses }}
      </div>
      <div class="text-sm">
        {{ declination(bonuses, 'бонус||а|ов') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const customerStore = useCustomerStore()

const bonuses = computed(() => customerStore.bonuses?.balance || 0)
</script>
